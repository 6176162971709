<script lang="ts" setup>
import NormalLayout from "@/layouts/NormalLayout.vue";
import KincirTable from "@/components/kincir/KincirTable.vue";
import Summary from "@/components/kincir/Summary.vue";
import {type Ref, ref} from "vue";
import {RouterLink} from "vue-router";
import {useHead} from "@unhead/vue";
import Helper from "@/helper";

// SEO
useHead({
  title: 'Batu Ice | Pola Kincir Litmatch',
  meta: [
    {
      name: 'description',
      content: 'Pahami pola Kincir Litmatch, analisa pola-pola sepanjang hari.'
    }
  ],
  link: [
    {rel: 'canonical', href: Helper.getCanonicalURL()},
  ],
})

const duration: Ref<string> = ref('30m')

const durationList: string[] = ['30m', '1h', '2h', '4h', '12h', '24h', '48h']

// TODO: set active duration into url query


</script>

<template>
  <NormalLayout>
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row">
          <div class="col-lg-7 col-md-7">
            <div class="row g-2 align-items-center">
              <div class="col">
                <div class="page-pretitle">Live Outcome</div>
                <h1 class="page-title">Kincir</h1>
              </div>

              <!-- Page title action -->
              <div class="col-auto ms-auto d-print-none">
                <span class="d-inline">
                  <RouterLink class="btn btn-outline-secondary" to="/kincir/download">
                    Download
                  </RouterLink>
                </span>
              </div>

            </div>
          </div>
        </div>

        <!-- Sponsor -->
        <!--
        <div class="row mt-2">
          <div class="col-lg-7 col-md-7 text-end">
            <small class="text-muted"><i>Sponsor</i></small>
          </div>
          <div class="col-lg-7 col-md-7">
            <a data-ph-capture-attribute-sponsor-id="241116-topup-diamond"
               href="https://litmatchapp.com/ssr/share-homepage?userid=633bfcd81c74ef77dfec6adb&locale=US&share_type=homepage&source=whatsApp&nickname=Gaspar"
               target="_blank"
            >
              <picture>
                <source media="(min-width: 500px)" srcset="/sponsor/241116-topup-diamond-742x120.gif">
                <img alt="Sponsor" class="img-fluid" src="/sponsor/241116-topup-diamond-500x140.gif">
              </picture>
            </a>
          </div>
        </div>
        -->

      </div>
    </div>


    <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-lg-7 col-md-7">

            <div class="col text-end overflow-x-scroll">
              <div class="btn-group" role="group">
                <template v-for="d in durationList">
                  <input :id="`radio-duration-${d}`" v-model="duration" :value="d" class="btn-check" name="duration"
                         type="radio">
                  <label :for="`radio-duration-${d}`" class="btn" type="button">{{ d }}</label>
                </template>
              </div>
            </div>

            <Summary :key="duration" :duration="duration" class="sticky-top mt-2"/>

            <Transition name="lift">
              <KincirTable :key="duration" :duration="duration" class="mt-2"/>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </NormalLayout>
</template>

<style scoped>
.lift-leave-from,
.lift-enter-to {
  opacity: 0;
  transform: translateY(30px);
}

.lift-enter-from,
.lift-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.lift-leave-active,
.lift-enter-active {
  transition: all 0.5s ease;
}
</style>